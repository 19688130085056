import { UpdateExtrasRequest } from '@zola/svc-marketplace-ts-types';

import type { AppDispatch, AppThunk } from '~/reducers';
import type { ExtrasDetails } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import Logger from '~/util/logger';

import {
  receivedExtrasDetails,
  requestingExtrasDetailsByStorefront,
  updatedExtrasDetails,
  updatingExtrasDetails,
} from '../types/extrasActionTypes';

export const getExtrasDetailsByStorefront = (storefrontUuid: string): AppThunk<Promise<void>> => {
  return (dispatch) => {
    dispatch(requestingExtrasDetailsByStorefront());
    return ApiService.get<ExtrasDetails>(
      `/web-marketplace-api/v1/manage/extras/for-storefront/${storefrontUuid}`
    ).then((extrasDetails: ExtrasDetails) => {
      dispatch(receivedExtrasDetails(extrasDetails));
    });
  };
};

export const updateExtrasDetails = (
  extrasDetails: ExtrasDetails,
  // TODO: Should be options? This is included in the update request, but isn't used by the service
  facetKeys: string[] | null = null
) => {
  return (dispatch: AppDispatch) => {
    dispatch(updatingExtrasDetails());
    return ApiService.put<ExtrasDetails, UpdateExtrasRequest>(
      `/web-marketplace-api/v1/manage/extras/${extrasDetails.uuid}`,
      { ...extrasDetails, facetKeys }
    )
      .then((json: ExtrasDetails) => {
        return dispatch(updatedExtrasDetails(json));
      })
      .catch((error) => {
        Logger.error(error.message, error);
      });
  };
};
