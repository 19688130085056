import { UpdateOfficiantRequest } from '@zola/svc-marketplace-ts-types';

import type { AppDispatch, AppThunk } from '~/reducers';
import type { OfficiantDetails } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import Logger from '~/util/logger';

import {
  receivedOfficiantDetails,
  requestingOfficiantDetailsByStorefront,
  updatedOfficiantDetails,
  updatingOfficiantDetails,
} from '../types/officiantActionTypes';

export const getOfficiantDetailsByStorefront = (
  storefrontUuid: string
): AppThunk<Promise<void>> => {
  return (dispatch) => {
    dispatch(requestingOfficiantDetailsByStorefront());
    return ApiService.get<OfficiantDetails>(
      `/web-marketplace-api/v1/manage/officiant/for-storefront/${storefrontUuid}`
    ).then((officiantDetails: OfficiantDetails) => {
      dispatch(receivedOfficiantDetails(officiantDetails));
    });
  };
};

export const updateOfficiantDetails = (
  officiantDetails: OfficiantDetails,
  // TODO: Should be options? This is included in the update request, but isn't used by the service
  facetKeys: string[] | null = null
) => {
  return (dispatch: AppDispatch) => {
    dispatch(updatingOfficiantDetails());
    return ApiService.put<OfficiantDetails, UpdateOfficiantRequest>(
      `/web-marketplace-api/v1/manage/officiant/${officiantDetails.uuid}`,
      { ...officiantDetails, facetKeys }
    )
      .then((json: OfficiantDetails) => {
        return dispatch(updatedOfficiantDetails(json));
      })
      .catch((error) => {
        Logger.error(error.message, error);
      });
  };
};
